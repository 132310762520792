import { PageType, ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { Gender } from "@multimediallc/gender-utils"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { ignoreMetaClick } from "../../../common/DOMutils"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"

export interface FollowedOnlineOfflineTabProps {
    online?: boolean
    onFilterClick?: (event: MouseEvent) => void
}

export class FollowedOnlineOfflineTab extends HTMLComponent<HTMLElement, FollowedOnlineOfflineTabProps> {
    // This component manages the bolded & non-bolded text used to toggle between displaying
    // online and offline followed cams

    protected createElement(): HTMLElement {
        return <div
            bind={{
                display: () =>
                    UrlState.current.state.showType === ShowType.FOLLOW || UrlState.current.state.showType === ShowType.FOLLOW_OFFLINE ? "block" : "none",
                style: () => { return { marginBottom: UrlState.current.state.showType === ShowType.FOLLOW_OFFLINE  ? "12px" : "" } },
            }}
            className="followed_online_offline" data-testid="followed-online-offline">
            <FollowedOnlineOfflineFilter onlineFilter={true}/>
            <FollowedOnlineOfflineFilter onlineFilter={false}/>
        </div>
    }

    initUI(props?: FollowedOnlineOfflineTabProps): void {
        super.initUI(props)
        UrlState.current.listen(["showType"], () => {
            this.updateState()
        }, this.element)
        this.updateState()
    }
}

interface IFollowedOnlineOfflineFilter { onlineFilter: boolean }

class FollowedOnlineOfflineFilter extends HTMLComponent<HTMLDivElement, IFollowedOnlineOfflineFilter> {
    private currentGender: Gender

    protected createElement(props: IFollowedOnlineOfflineFilter): HTMLDivElement {
        return <div className="title"
            bind={{ class: () => `title${this.isActive(props.onlineFilter) ? " bold" : ""}` }}
            data-testid={props.onlineFilter ? "online-filter-title" : "offline-filter-title"}
            onClick={(e) => {
                ignoreMetaClick(e, () => {
                    const targetUrl = this.getHref(props.onlineFilter)
                    // don't cache the cleared filter state when navigating to the offline tab
                    UrlState.current.navigateTo(targetUrl, !props.onlineFilter)
                })
            }}
        >
            <p>
                <a
                    bind={{ href: () => this.getHref(props.onlineFilter) }}
                    data-testid={props.onlineFilter ? "online-filter-link" : "offline-filter-link"}
                >{props.onlineFilter ? i18n.onlineRooms : i18n.offlineRooms}</a>
            </p>
        </div>
    }

    initUI(props?: IFollowedOnlineOfflineFilter): void {
        super.initUI(props)
        // we track gender here since it is removed from url state when navigating to the offline followed page
        this.currentGender = UrlState.current.state.genders?.[0] ?? Gender.All
        UrlState.current.listen(["genders", "showType"], (state) => {
            if (state.showType === ShowType.FOLLOW) {
                this.currentGender = state.genders?.[0] ?? Gender.All
            }
            this.updateState()
        }, this.element)
    }

    private getHref(online: boolean): string {
        const showType = online ? ShowType.FOLLOW : ShowType.FOLLOW_OFFLINE
        // Cached filters will be auto-applied when navigating to the online followed page, and all other filters will
        // be *ignored* by the offline followed page, so don't bother retaining other filter state params in the HREFs
        const targetState: IURLState = { pageType: PageType.HOME, showType: showType }
        if (online) {
            targetState.genders = [this.currentGender]
        }
        return UrlState.current.convertStateToUrl(targetState)
    }

    private isActive(onlineFilter: boolean): boolean {
        return onlineFilter ? UrlState.current.state.showType === ShowType.FOLLOW : UrlState.current.state.showType === ShowType.FOLLOW_OFFLINE
    }
}
