import { getCategorySlug, ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isEmptySearchResultsPageActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { currentSiteSettings } from "../../siteSettings"

interface IRoomlistMessageProps {
    categorySlug?: string
    appName?: string
    isSecondContainer?: boolean
}

export class RoomlistMessage extends HTMLComponent<HTMLDivElement, IRoomlistMessageProps> {
    constructor(props: IRoomlistMessageProps) {
        super(props)
    }

    protected initUI(): void {
        UrlState.current.listen(["ageMax", "ageMin", "apps", "aspApp", "showType"], () => {
            this.updateState()
        }, this.element)
    }

    private getMessageFromUrlState(props: IRoomlistMessageProps): string {
        if ((UrlState.current.state.apps !== undefined || UrlState.current.state.aspApp !== undefined) && props.appName !== undefined) {
            return i18n.usersRunningAppMessage(props.appName)
        } else {
            const category = getCategorySlug()
            if (props.isSecondContainer ?? false) {
                return i18n.camCategoryHidden
            } else {
                return ROOMLIST_MESSAGE_MAP_SHORT.get(category) ?? ""
            }
        }
    }

    private getPremiumDescription(props: IRoomlistMessageProps): string | undefined {
        if (UrlState.current.state.showType === ShowType.HIDDEN ||
            UrlState.current.state.showType === ShowType.PRIVATE && props.isSecondContainer === true
        ) {
            return i18n.categoryDescriptionHidden
        } else if (UrlState.current.state.showType === ShowType.PRIVATE) {
            return i18n.categoryDescriptionPrivate(currentSiteSettings.siteName)
        }
    }

    protected createElement(props: IRoomlistMessageProps): HTMLDivElement {
        return (
            <div className={`roomlist-msg${isEmptySearchResultsPageActive() ? " EmptySrchRes" : ""}`}
                bind={{
                    display: () => {
                        const text = this.getMessageFromUrlState(props)
                        return text !== "" ? "block" : "none"
                    }, 
                }}
            >
                <h2 data-testid="roomlist-message" bind={{ text: () => this.getMessageFromUrlState(props) }}>
                </h2>
                <p bind={{ text: () => this.getPremiumDescription(props) }} className="premium-desc"></p>
            </div>
        )
    }
}

const ROOMLIST_MESSAGE_MAP_SHORT = new Map<string, string>([
    ["mature-cams", i18n.camCategoryMature],
    ["exhibitionist-cams", i18n.camCategoryExhib],
    ["spy-on-cams", i18n.camCategoryPrivate],
    ["hidden-cams", i18n.camCategoryHidden],
    ["new-cams", i18n.camCategoryNewCams],
    ["gaming-cams", i18n.camCategoryGaming],
])
