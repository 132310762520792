import { t } from "@lingui/macro"
import {
    DEFAULT_PAGESIZE,
    getPaginationAPIParams,
    ROOMLIST_API_URL,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isEmptySearchResultsPageActive } from "../../../common/featureFlagUtil"
import { dom } from "../../../common/tsxrender/dom"
import { PaginatedApiRoomList } from "./paginatedApiRoomList"
import { RoomReloadErrorNotification } from "./roomReloadErrorNotification"
import { showFallbackRoomlist } from "./spaHelpers"
import type { ListenerGroup } from "../../../common/events"
import type { IRoomListAPIParams } from "@multimediallc/cb-roomlist-prefetch"

export const SHOW_FALLBACK_THRESHOLD = 8  // Only show fallback if fewer results than this

interface IFallbackContainerProps {
    animate: boolean
    showLocation: boolean
    mobile?: boolean
}

export class FallbackRoomlistContainer extends HTMLComponent<HTMLDivElement, IFallbackContainerProps> {
    protected roomlist: PaginatedApiRoomList
    protected listeners: ListenerGroup
    private roomReloadErrorNotification: RoomReloadErrorNotification

    protected createElement(props: IFallbackContainerProps): HTMLDivElement {
        const isEmptySrchRes = isEmptySearchResultsPageActive()
        return <div
            className="roomlist_container endless_page_styles"
            data-testid="fallback-room-list-container"
            style={{
                display: "none",
                marginTop: isEmptySrchRes || props.mobile === true ? "0px" : "-40px",
            }}
        >
            <h2 style={{ textAlign: isEmptySrchRes ? "left" : "center" }} className={isEmptySrchRes ? "roomlist-msg EmptySrchRes" : ""}>
                {isEmptySrchRes ? t`Other Featured Cams:` : t`Consider some of these other featured cams:`}
            </h2>
            <RoomReloadErrorNotification classRef={(c) => { this.roomReloadErrorNotification = c }} />
            <PaginatedApiRoomList classRef={(c) => { this.roomlist = c }}
                animate={props.animate}
                showLocation={props.showLocation}
                apiUrl={ROOMLIST_API_URL}
                pageSize={DEFAULT_PAGESIZE}
                hashtag={() => undefined}  // Don't log tag filters on the primary roomlist for the fallback
            />
        </div>
    }

    protected initUI(props: IFallbackContainerProps): void {
        this.roomReloadErrorNotification.hideElement()
        showFallbackRoomlist.listen((matchedCount: number) => {
            if (this.isFallbackSupported() && matchedCount < SHOW_FALLBACK_THRESHOLD) {
                this.loadRooms()
                this.showElement()
            } else {
                this.hideElement()
            }
        })
    }

    public handleRoomRefresh(): void {
        if (this.isShown()) {
            this.loadRooms()
        }
    }

    private isFallbackSupported() {
        return ![
            ShowType.PRIVATE,
            ShowType.FOLLOW,
            ShowType.FOLLOW_OFFLINE,
        ].includes(UrlState.current.state.showType as ShowType)
    }

    /**
     * Loads room from the API call updating all the corresponding components.
     */
    loadRooms(): void {
        this.roomlist.fetchRooms(undefined, this.apiFilters).then(({ totalCount, matchedCount, page }) => {
            this.roomReloadErrorNotification.hideElement()
        }).catch((err) => {
            error("Failed to load roomlist page after retrying", err)
            this.roomReloadErrorNotification.showElement()
        })
    }

    protected get apiFilters(): IRoomListAPIParams {
        /* eslint-disable @typescript-eslint/strict-boolean-expressions */
        const params: IRoomListAPIParams = getPaginationAPIParams()
        if (UrlState.current.state.genders?.length) {
            params.genders = UrlState.current.state.genders.join(",")
        }
        if (UrlState.current.state.regions?.length) {
            params.regions = UrlState.current.state.regions.join(",")
        }
        params.fallback = true
        return params
    }
}
