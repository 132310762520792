import {
    getPageHashtag,
    isRoomRoomlistSpaActive,
    saveStickyFilters,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { RoomListSource } from "@multimediallc/web-utils/types"
import { loadRoomRequest } from "../../../common/fullvideolib/userActionEvents"
import { dom, Fragment } from "../../../common/tsxrender/dom"
import { parseQueryString } from "../../../common/urlUtil"
import { pageContext } from "../../interfaces/context"
import { RoomFollowStar } from "../followStar"
import { PaginatedApiRoomsIterator } from "./paginatedAPIRoomsIterator"
import { RoomCard } from "./roomCard"
import { RoomList } from "./roomList"
import { shouldShowJoinOverlay } from "./spaHelpers"
import type { IRoomInfo } from "./IRoomInfo"
import type { IRoomCardProps } from "./roomCard"
import type { IRoomClickDetails, IRoomListProps } from "./roomList"
import type { RoomSourceOptionalFields } from "../../roomList"
import type { IRoomListAPIParams } from "@multimediallc/cb-roomlist-prefetch"


interface IApiRoomlistProps extends Omit<IRoomListProps, "rooms" | "roomListSource"> {
    apiUrl: string
    pageSize: number
    pageParam?: string
}

export interface IRoomsLoadResult {
    totalCount: number
    matchedCount: number
    page?: number
}


interface IApiRoomlistState {
    loadedRooms: IRoomInfo[]
    roomListId?: string
    blsPayload?: string
}

export class PaginatedApiRoomList extends RoomList<HomePageRoomCard, IApiRoomlistState> {
    private roomsIterator: PaginatedApiRoomsIterator
    // categoryFilters are filter values that remain static for a given page, i.e. filters set by category
    // page roomlists which can't be toggled by the user. They're tracked separately from the "dynamic"
    // filter values and override specific user preferences when applicable (mostly just region checkboxes)
    private fetchId = 0  // Used for resolving potential race conditions
    private readonly placeholderRooms: HTMLElement[]

    constructor(props: IApiRoomlistProps) {
        super({
            roomListSource: RoomListSource.Unknown,
            animate: props.animate,
            showLocation: props.showLocation,
            rooms: () => this.state.loadedRooms,
            // Optional source info properties. `sourceIndex` doesn't apply since this is a top-level roomlist.
            hashtag: props.hashtag ?? (() => getPageHashtag(true)),
            sourceInfo: props.sourceInfo ?? ((evt) => evt.roomInfo.sourceInfo),
        })

        if (!isRoomRoomlistSpaActive()) {
            this.listeners.add(loadRoomRequest.listen((room) => {
                if (parseQueryString(window.location.search)["join_overlay"] !== undefined && shouldShowJoinOverlay()) {
                    // If join overlay is enabled, ignore loadRoomRequest and allow overlay handler to intercept
                    return
                }
                window.location.assign(`/${room}/`)

                // Pass syncHistory as false to prevent Safari errors. Due to caching behavior, if we navigate into a room
                // via loadRoomRequest then return to the roomlist via the back button, the loadRoomRequest event will still
                // be in the EventRouter history. If we then construct a new roomlist after pageload (such as when navigating
                // to the /spy-on-cams/ page), this listener will fire IMMEDIATELY when added, taking us back to the room.
            }, false))
        }

        this.roomsIterator = new PaginatedApiRoomsIterator({
            apiUrl: props.apiUrl,
            pageSize: props.pageSize,
        })
        this.state.loadedRooms = []
        // here we clone the placeholder room cards from the template which is slightly faster than creating
        // these elements from the scratch. This would also allow us to have the placeholder elements only in one
        // place which would be the django template. Alternatively, we could generate these placeholders on the
        // prefetch step and use them here.
        const placeholderList = document.querySelector(".roomlist_container.placeholder ul") as HTMLElement
        if (placeholderList !== null) {
            this.placeholderRooms = Array.from((placeholderList.cloneNode(true) as HTMLElement).children) as HTMLElement[]
            // The initial template placeholders are cleaned up here because the mobile root is not entirely
            // replaced with the new mobile root defined in TS.
            if (pageContext.current.isMobile) {
                placeholderList.parentElement!.remove()
            }
        }
        this.roomClickEvent.listen(() => {
            // When we navigate to a room, we should save the sticky filters in case they are from
            // a footer link and haven't been saved yet.
            saveStickyFilters(UrlState.current.state)
        })
    }

    /** Base-class method overrides **/

    protected createElement(): HTMLUListElement {
        // We need RoomReload to recognize the roomlist container, so it fires the onSuccess handler and
        // correctly hides/shows the searching overlay etc., but this component should handle its own
        // API requests for room reloads, hence adding .endless_page_template with the skip attribute
        return <ul className="list endless_page_template" data-testid="room-list-container" data-href="skip"></ul>
    }

    protected createRoomCard(roomProps: IRoomCardProps): HomePageRoomCard {
        return new HomePageRoomCard(roomProps)
    }

    protected getOptionalSourceInfo(evt: IRoomClickDetails, props: IRoomListProps): RoomSourceOptionalFields {
        const sourceInfo = super.getOptionalSourceInfo(evt, props)
        if (this.state.roomListId !== undefined) {
            sourceInfo["roomListId"] = this.state.roomListId
        }
        if (this.state.blsPayload !== undefined) {
            sourceInfo["blsPayload"] = this.state.blsPayload
        }
        return sourceInfo
    }

    /** Room chunk loading methods **/

    /**
     * Fetches a page of rooms from the room iterator and updates the state accordingly, triggering a re-render.
     * Increments this.fetchId for the sake of race-condition avoidance.
     * @param prefetchPromise Optional, if provided will attempt to get results from this promise rather than
     *     making a new API request (will retry with new requests as typical upon failure, however)
     * @param filters Optional, if provided will set the filters for the room iterator before fetching
     */
    public fetchRooms(prefetchPromise?: Promise<string>, filters?: IRoomListAPIParams): Promise<IRoomsLoadResult> {
        this.fetchId += 1
        if (filters !== undefined) {
            this.roomsIterator.setFilters(filters)
        }
        this.showLoading(prefetchPromise !== undefined)
        return new Promise((resolve, reject) => {
            this.roomsIterator.fetchPage(this.fetchId, prefetchPromise)
                .then(({ loadedRooms, totalCount, matchedCount, roomListId, blsPayload, fetchId }) => {
                    if (fetchId !== this.fetchId) {
                        return  // A more recent room fetch request was fired before this one resolved, discard results
                    }
                    this.setState({
                        ...this.state,
                        loadedRooms,
                        roomListId,
                        blsPayload,
                    })
                    resolve({
                        totalCount,
                        matchedCount,
                    })
                }).catch((err) => {
                    reject(err)
                }).finally(() => {
                    this.hideLoading()
                })
        })
    }

    /** Direct DOM manipulation helper methods **/

    public showLoading(isFirstLoad: boolean): void {
        if (this.state.loadedRooms.length === 0) {
            if (this.placeholderRooms !== undefined) {
                this.element.append(...this.placeholderRooms)
                if (!isFirstLoad) {
                    // if it's not the first load, we add less placeholder cards to avoid massive layout shift
                    this.element.style.maxHeight = `${this.placeholderRooms[0].offsetHeight + 1}px`
                }
            }
        } else {
            // Only show the loading overlay if we aren't showing placeholder cards, to create a visual
            // difference in the previously-valid room cards that are still displayed in the interim.
            this.element.classList.add("loading")
        }
    }

    private hideLoading() {
        // Ensure .loading overlay is not present -- no need to remove any placeholder cards
        // since that's handled automatically by resetRooms()
        this.element.classList.remove("loading")
        this.placeholderRooms?.forEach(el => el.remove())
        this.element.style.maxHeight = ""
    }

    removeAllChildren(): void {
        super.removeAllChildren()
        this.removeAllDOMChildren()
    }
}

class HomePageRoomCard extends RoomCard {
    /**
     * Temporary component to fix interactions between components that extend from FollowStar and components that extend from the DropDownComponent.
     * TODO: Look into a permanent fix for component interaction. Due to usage of stopPropagation within FollowStar, documentClickEvent is never fired
     * to hide DropDownComponents.
     * https://multimediallc.leankit.com/card/30502080131375
     **/
    protected createFollowStar(props: IRoomCardProps): RoomFollowStar {
        return <RoomFollowStar slug={props.roomInfo.room} isFollowing={props.roomInfo.isFollowing}
            classRef={(c: RoomFollowStar) => this.followStar = c} allowPropagation={true}
        />
    }

    // eslint-disable-next-line complexity
    protected createElement(props: IRoomCardProps): HTMLLIElement {
        const el = super.createElement(props)
        const extendedInfo = props.roomInfo.extendedInfo
        if (extendedInfo !== undefined) {
            // If extended room info is defined (i.e. if we're on internal.chaturbate and URL contains ?show_rankings=1)
            // then add an additional child div that exposes the extended ranking information
            let penaltyText
            if (Boolean(extendedInfo.manualPenalty)) {
                penaltyText = <Fragment><s>{extendedInfo.defaultPenalty}</s> <b>{extendedInfo.manualPenalty}</b></Fragment>
            } else {
                penaltyText = <b>{extendedInfo.defaultPenalty}</b>
            }
            const coloredRegs = extendedInfo.coloredRegs ?? 0
            // We want the displayed regs count to NOT include colored regs since they're shown separately
            const numRegs = (extendedInfo.numRegs ?? 0) - coloredRegs
            el.appendChild(<div className="extended-room-info">
                <span><b>Default:</b> #{extendedInfo.scoreRank ?? "X"}</span><br />
                <span><b>Base:</b> #{extendedInfo.baseRank ?? "X"}</span><br />
                <span><b>Satisfaction:</b> {extendedInfo.satPercent ?? "X"}%</span><br />
                <span><b>Penalty:</b> {penaltyText}</span><br />
                <span><b>Grey:</b> {numRegs} | Color: {coloredRegs}</span><br />
                <span><b>Tip rate:</b> {(extendedInfo.tipRate ?? 0).toFixed(2)}/hr</span><br />
                {(extendedInfo.smallRoomScore ?? 0) > 0 ?
                    <span><b>Small room score:</b> {extendedInfo.smallRoomScore}</span>
                    : ""
                }
            </div>)
        }
        return el
    }
}
