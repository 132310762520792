import { DEFAULT_PAGESIZE } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { dom } from "../../../common/tsxrender/dom"
import { Pagination } from "../pagination/pagination"
import type { IPaginationProps, IPaginationState } from "../pagination/pagination"

type RoomlistPaginationOmitted = "makeResponsive" | "addLastPageAction"|  "reloadOnPageChange" | "itemsPerPage"
type IRoomlistPaginationProps = Omit<IPaginationProps, RoomlistPaginationOmitted>
type IRoomlistPaginationState = Omit<IPaginationState, RoomlistPaginationOmitted>
export class RoomlistPagination extends HTMLComponent<HTMLDivElement, IRoomlistPaginationProps, IRoomlistPaginationState> {
    private pagination: Pagination
    protected createElement(props: IPaginationProps): HTMLDivElement {
        return <div id="roomlist_pagination"
            data-testid="room-list-pagination-component"
            bind={{
                display: () =>
                    this.state.totalItems !== undefined &&
                            this.state.totalItems !== 0 ?
                        "flex" : "none",
            }}
        >
            <Pagination classRef={(c) => {
                this.pagination = c
            }}
            itemsPerPage={DEFAULT_PAGESIZE}
            onPageChange={props.onPageChange}
            reloadOnPageChange={false}
            addLastPageAction={true}
            pageParam={props.pageParam}
            makeResponsive={true}
            />
        </div>
    }

    isLastPage(): boolean {
        return this.pagination.isLastPage()
    }

    setState(state: IRoomlistPaginationState): void {
        super.setState(state)
        this.pagination.setState(
            state,
        )
    }
}
