import { ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isEmptySearchResultsPageActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"


interface INumOfRooms {
    total: number
    matched: number
    isLastPage: boolean
    hasFilter: boolean
}

interface INumOfRoomsProps {isSecondary: boolean}

export class NumberOfRoomsMessage extends HTMLComponent<HTMLDivElement, INumOfRoomsProps, INumOfRooms> {
    private isEmptySrchResActive: boolean

    initData(props: INumOfRoomsProps): void {
        super.initData(props)
    }

    protected createElement(props: INumOfRoomsProps): HTMLDivElement {
        this.isEmptySrchResActive = isEmptySearchResultsPageActive()
        return <div className={`num-of-rooms-msg${this.isEmptySrchResActive ? " EmptySrchRes" : ""}`} data-testid="num-of-rooms-msg" bind={{
            display: () => {
                const isMessageShownEligible = this.state.isLastPage && this.state.hasFilter && UrlState.current.state.showType !== ShowType.FOLLOW_OFFLINE
                const isEmptySrchResActiveEligilble = this.isEmptySrchResActive && this.state.matched > 0 || !this.isEmptySrchResActive
                return isMessageShownEligible && isEmptySrchResActiveEligilble ? "flex" : "none"
            },
            text: () => this.getMessage(props),
        }}>
        </div>
    }

    private getMessage(props: INumOfRoomsProps) {
        if (this.state.matched > 0) {
            return this.getRoomsMatchedMessage(props)
        } else {
            return this.getNoRoomsMatchedMessage(props)
        }
    }

    private shouldShowHiddenRoomsMessage(props: INumOfRoomsProps) {
        return props.isSecondary || UrlState.current.state.showType === ShowType.HIDDEN
    }

    private getRoomsMatchedMessage(props: INumOfRoomsProps): string {
        if (this.shouldShowHiddenRoomsMessage(props)) {
            return i18n.numberOfHiddenRoomsMatchesYourFilters(this.state.total, this.state.matched)
        } else if (UrlState.current.state.showType === ShowType.PRIVATE) {
            return i18n.numberOfPrivateRoomsMatchesYourFilters(this.state.total, this.state.matched)
        } else if (UrlState.current.state.showType === ShowType.FOLLOW) {
            return i18n.numberOfFollowedRoomsMatchesYourFilters(this.state.total, this.state.matched)
        } else if (this.isEmptySrchResActive && UrlState.current.state.showType === ShowType.EXHIB) {
            return i18n.numberOfExhibitionistCamsMatchesYourFilters(this.state.total, this.state.matched)
        } else if (this.isEmptySrchResActive && UrlState.current.state.showType === ShowType.NEW_CAMS) {
            return i18n.numberOfNewCamsMatchesYourFilters(this.state.total, this.state.matched)
        } else if (this.isEmptySrchResActive && UrlState.current.state.showType === ShowType.GAMING) {
            return i18n.numberOfGamingCamsMatchesYourFilters(this.state.total, this.state.matched)
        } else {
            return i18n.numberOfRoomsMatchesYourFilters(this.state.total, this.state.matched)
        }
    }

    private getNoRoomsMatchedMessage(props: INumOfRoomsProps): string {
        if (this.shouldShowHiddenRoomsMessage(props)) {
            return i18n.noHiddenRoomsMatchesYourFilters(this.state.total)
        } else if (UrlState.current.state.showType === ShowType.PRIVATE) {
            return i18n.noPrivateRoomsMatchesYourFilters(this.state.total)
        } else if (UrlState.current.state.showType === ShowType.FOLLOW) {
            return i18n.noFollowedRoomsMatchesYourFilters(this.state.total)
        } else if (this.isEmptySrchResActive && UrlState.current.state.showType === ShowType.EXHIB) {
            return i18n.noExhibitionistCamsMatchesYourFilters(this.state.total)
        } else if (this.isEmptySrchResActive && UrlState.current.state.showType === ShowType.NEW_CAMS) {
            return i18n.noNewCamsMatchesYourFilters(this.state.total)
        } else if (this.isEmptySrchResActive && UrlState.current.state.showType === ShowType.GAMING) {
            return i18n.noGamingCamsMatchesYourFilters(this.state.total)
        } else {
            return i18n.noRoomsMatchesYourFilters(this.state.total)
        }
    }
}
